import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import "react-accessible-accordion/dist/fancy-example.css";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { CaseStudyCtaGoTo } from "./generative-ai-itsm";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/mtbsd_blog_header.png");
const section_1 = require("../../../assets/img/blogs/mtbsd_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/mtbsd_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/mtbsd_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/mtbsd_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/mtbsd_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/mtbsd_blog_img_6.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="MS Teams Bot for Service Desk Transformation"
        description="Learn how MS Teams Bot leads to service desk transformation. Scale your service desk with MS Teams Bot"
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt mtbsd_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img ${
                  isMobile ? "" : "float-left"
                }`}
              >
                {isMobile ? (
                  <h1
                    className={`${
                      isMobile
                        ? "font-page-header-home-blog-gpt"
                        : "font-page-header-home-blog-gpt"
                    }`}
                  >
                    How MS Teams Bot leads to Service Desk Transformation
                  </h1>
                ) : (
                  <h1
                    className={`${
                      isMobile
                        ? "font-page-header-home-blog"
                        : "font-page-header-home-blog"
                    }`}
                  >
                    How MS Teams Bot
                    <br /> leads to Service Desk
                    <br /> Transformation
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Challenges with traditional service desk
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. How does MS Teams Bot enhance service desk transformation?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Other advantages of using Microsoft Team Bots in the
                  service desk include:
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. MS Teams Bot for Service Desk Use cases
                </AnchorLink>

                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Did you know you can transform your Teams into an internal
                  IT help desk with on-the-go self-service?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Enterprise operations maturity for operational efficiency"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                Every year, billions of dollars are spent on software linked to
                digital transformation. IT Service Desks are now in charge of
                integrating these new service desk software installations tied
                to massive digital transformation programs. Consequently, ticket
                traffic increases, putting pressure on ticketing costs and the
                IT service desk. Turn your familiar communication tool Microsoft
                Teams into a Service Desk with bot integration and app workflow
                automation.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Teams Service Desk helps the IT service desk become lean and
                flexible, allowing end-users to remain afloat and sail over the
                technological tsunami. By helping to automate routine tasks,
                these Microsoft Teams bots are streamlining processes and
                connecting employees and customers. It can also be used to
                execute the ITIL framework's best practices for integrating
                service delivery with strategic goals.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                How do you determine “ your business needs a conversational AI
                MS Teams Bot for the service desk”? Given your passive or
                reactive outlook in handling service desk issues, the question
                may seem irrelevant. But, workplace productivity, especially
                when you have a growing team and large client base, real-time
                and end-to-end service delivery is primary.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft, in its{" "}
                <a href="https://www.microsoft.com/en-us/worklab/four-ways-leaders-can-empower-people-for-how-work-gets-done">
                  survey,
                </a>{" "}
                highlighted 77% of respondents' view on low-code or no-code
                solutions to be helping them achieve their goals at work. On the
                other hand, 89% of respondents cited that they are more
                productive and fulfilled with automation and AI-powered tools.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this context, it wouldn’t be wrong to say that service desk
                transformation through AI-powered MS Teams bot integration,
                including app workflow automation, would serve the purpose of
                what employees need to resolve issues and thrive at the
                workplace.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                This blog will identify service desk issues and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  the need for service desk transformation.
                </a>{" "}
                We'll further examine how the{" "}
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  Microsoft Teams bot for the service desk
                </a>{" "}
                delivers process efficiency and business resilience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Challenges with traditional service desk
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Streamlining work processes is the key objective of a service
                desk. But, when your service desk is built on a traditional
                model, it gradually adds to poor employee experience and hits
                the bottom line. Below are some striking challenges of a service
                desk.
              </p>
              <BlogCta
                ContentCta="Auto-resolve 60% of Employee Queries with MS Teams Bot."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <h3 className="font-section-sub-header-small-home">
                A steep learning curve needed
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Suppose you have ever noticed a recruitment drive for an ITSM
                manager or expert. In that case, it generally focuses on key
                expertise areas to handle major modules of a service desk
                platform in mitigating IT incidents and ensuring business
                continuity.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A steep learning curve about the platform architecture can be
                ideal for handling service desk tickets and driving employee
                efficiency. It means a service desk is built to help agents to
                resolve issues.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                What if end users do not possess a sound knowledge of the
                platform? Instead of using the service desk portal, they tend to
                use multiple methods such as emails, phone calls, or one-to-one
                interaction to communicate the issue.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A bottom line crisis as your investment remains unutilized.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Lengthy Mean Time to Resolution (MTTR)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                As per Service Desk Show,{" "}
                <a href="https://www.servicedeskshow.com/blog-spot/does-my-company-need-a-conversational-ai-chatbot/">
                  monthly tickets have grown by 35% over the two years.
                </a>{" "}
                Coupled with the complexity of issues and a backlog of tickets,
                there is a rush to handle as many tickets as possible, which is
                a possible reason for committing mistakes and handling the same
                tickets repeatedly. Besides, a lack of context to the actual
                issue of a ticket usually prolongs the MTTR for a service desk
                agent.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Handling multiple requests altogether
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                A traditional service desk is not centralized and is supported
                by phone calls and emails. As a result,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Phone calls take more time per user to triage and close
                  tickets
                </li>
                <li className="font-section-normal-text-testimonials">
                  Emails make it challenging to track progress back and forth,
                  causing delays in ticket resolution
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                This perhaps increases the total count of tickets to your
                service desk involving the same or more complex issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Handling a wave of tickets from your growing team is
                nerve-wracking, mainly because you are yet to bring automation
                to your service desk. Adopting chatbots is a cost-effective
                option as these sophisticated algorithms can handle numerous
                customers simultaneously, lowering wait time and freeing support
                employees to focus on problem-solving.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Repetitive service desk issues
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                A help desk is susceptible to backlog issues when failing to
                resolve current issues at scale.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  IT help desk gets into the trap of resolving the same issues
                  as a traditional system lacks data analytics which can help
                  collect, record, and analyze support data.
                </li>
                <li className="font-section-normal-text-testimonials">
                  IT teams are unable to address more critical issues because of
                  high volumes of FAQ-based or common IT service-related
                  queries.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unable to keep track of the most common issues, service desk
                agents will waste time finding and addressing issues they have
                already worked on.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Lack of effective self-service interface
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Many of the issues dealt with by the IT service desk are
                regular, repetitive, and time-consuming.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Password resets account for 50 percent of IT service desk
                  calls
                </li>
                <li className="font-section-normal-text-testimonials">
                  Also, password reset or group email access may take more than
                  an hour to resolve due to the dependency on human involvement
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Where 80% of productivity losses happen due to requests for a
                repetitive issue, the ability to auto-resolve can reduce this
                probability. But, a traditional service desk does not incite
                quick adoption and increase service request volumes.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Long resolution times and unresolved issues
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The more tools and platforms in a business, the greater the
                requirement for faster troubleshooting. When the service desk
                gets too many requests to handle and lacks the appropriate
                communication tools, this will create massive damage. Many IT
                help desks use email to track client queries, and when employees
                manually sift through these inquiries, productivity suffers. The
                IT service desk also misses the chance to gain data that can
                identify recurrent issues. It ultimately speeds up future
                resolution times if it does not have a structured ticket intake
                approach and request tracking tools.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft Teams' strength lies in the fact that it enables users
                to interact while keeping in context. Bots created on the Teams
                platform operate as intelligent connectors between the Teams
                client and other services and processes, integrating them into
                your active collaboration platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They will help you save time by automating mundane chores with
                artificial intelligence. One of the most valuable features of
                Microsoft Teams is having a bot appear as any other team member
                to assist. T-Bot, for example, is a bot that answers commonly
                requested queries. Polly Bot may assist with polling among
                coworkers. Hipmunk can assist you in making travel arrangements.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of sending employees to a separate HR system to read
                vacation policies. a bot that tells them how many hours of
                vacation they have left for the year and walks them through the
                process without even leaving Microsoft Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  MS Teams bots have various applications, including{" "}
                </a>
                -One-to-one, group, and channel in a team. Users communicate
                with bots via text, interactive cards, and task modules, and
                interactions may vary from a basic search to a complicated
                AI-powered dialogue. Conversational bots make sense in Teams
                since it's a chat-based collaboration tool—the only difference
                is that you're conversing with a machine instead of another
                human. Great, isn't it?
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does MS Teams Bot enhance service desk transformation?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft Teams Bot for service desk can easily fetch service
                desk data inside your familiar collaboration channel Microsoft
                Teams through an API call. Chatbot builder such as{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ Assistant
                </a>{" "}
                helps you create app{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/enterprise-service-desk-guide">
                  workflow automation for service desk platforms
                </a>{" "}
                and enable handling IT or HR issues at scale inside the chat
                interface of MS Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s how your employee can achieve operational efficiency and
                drive business growth for you.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Ease of adoption for organization-wide users
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unlike traditional service desk platform, which needs a deep
                learning curve for adoption, MS Team Bot for service desk is
                easy to raise issues. Its chat interface acts as a service desk
                portal to raise tickets and get real-time help from an agent
                without continuously logging in and out of the system. It is a
                universal interface rather than just for agents and facilitates
                auto-resolutions rapidly and accurately.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Continuous updating of knowledge base content
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Traditional service desk incorporates knowledge materials with
                outdated information and offers no real value to the present-day
                service desk issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  An AI-modern service desk
                </a>{" "}
                keeps updating its knowledge repository and database by learning
                patterns of unique cases. As a result, employees need not wait
                for human help as they can easily fetch information from its
                portal. It is even easier to get knowledge articles and
                auto-resolve issues when a service desk is integrated with the
                MS Teams bot.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Real-time employee support
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is proven that the service desk is familiar with more than
                80% of repetitive tasks. ML and NLU-based service desk portal
                fetches real-time and appropriate knowledge base articles
                relevant to specific service desk issues.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                If knowledge articles are inappropriate, MS Teams Bot for the
                service desk automatically escalates the matter to the live
                agent. So, employees always get real-time employee support.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Self-service functionality
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is no denying that a modern service desk also comes with a
                dedicated self-service portal. But, it is pretty difficult for
                off-site or hybrid employees to connect with the portal, given
                the lack of easy access to system credentials.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                While MS Teams Bot for service desk is a familiar platform for
                employees 一 no matter whether remote or on-site, they can
                easily communicate their issues in the chat interface and get
                solutions rapidly within their portals.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Automated app workflows with service desk integration
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams bot for the service desk can flexibly adapt to any
                service desk infrastructure without major tweaks on the
                server-side end. A conversational AI platform, Workativ virtual
                assistant is easy to build your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/the-service-desk-trends-and-priorities-for-2023">
                  service desk chatbots for existing or new tools
                </a>{" "}
                such as ServiceNow, Freshservice, Zendesk, HaloITSM, and many
                more.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, leverage the integration and{" "}
                <a href="https://workativ.com/conversational-ai-platform/servicenow-chatbot">
                  automate app workflows
                </a>{" "}
                for just any service desk issues.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Accelerated resolution time
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Team bot integration fits seamlessly into IT service desk
                settings for ITSM automation, especially as Teams becomes more
                popular as a preferred route for employee communication. It's a
                go-to IT and HR channel for workers since it doesn't need them
                to log into service desk environments or portals. Their queries
                are auto-resolved in seconds utilizing AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ conversational AI is built to{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  reduce MTTR by 5X from hours to seconds.{" "}
                </a>
              </p>
              <h3 className="font-section-sub-header-small-home">
                Elimination of lower-level support tasks through automation
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams Bots for the service desk can assist with lower-lever
                support tasks to help lessen the strain on IT, admin, and
                support teams. This is a significant advantage of bots that has
                become more obvious as many workers have transitioned to remote
                work. The increased use of Teams has resulted in an
                overburdening of work for those working in customer service and
                IT. A "help bot" that can assist with requests addressed to IT
                is one of the most common bots people like to design.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Many inquiries that come in to help desks aren't actionable
                troubleshooting concerns. Therefore,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk">
                  help desk services are readily automated,
                </a>{" "}
                particularly tier-one-level replies. You can have standard
                answers available 24/7 using cutting-edge
                <a href="https://workativ.com/conversational-ai-platform/faq-chatbot-builder">
                  FAQ-based knowledge resources
                </a>{" "}
                for issues such as,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Printer issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Laptop issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Internet issues
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                When a bot can thoroughly diagnose and resolve an issue for a
                user, it saves time for IT professionals.
              </p>
              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
              <h3 className="font-section-sub-header-small-home">
                Serving as the perfect support center
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee productivity has increased dramatically due to the
                user-friendly interface combining the service desk and Teams.
                The speed with which difficulties are resolved doubles when
                Conversational AI, NLU, Cognitive Search, and NLP are used. MS
                Teams now has an AI-driven workflow automation engine that
                automates user processes and tasks.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Increase in employee satisfaction and engagement
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The ability of Microsoft Teams to modify self-service has been
                crucial in automating internal services and enhancing service
                desk productivity. Due to{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/benefits-of-ai-powered-service-desk">
                  AI-powered auto-resolution,
                </a>{" "}
                customers have seen an increase in user engagement and a
                significant drop in tickets because of Teams. What were the
                results? Employee satisfaction has risen, agent productivity has
                doubled, and average resolution rates have risen, making Service
                Desk efficiency a key performance indicator.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Other advantages of using Microsoft Team Bots in the service
                desk include:
              </h2>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Allow employees to respond to comments on complaints, approval
                  requests, and modifications using actionable alerts in
                  Microsoft Teams.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employees can access their pending assignments, the status of
                  their tickets, and business records.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Agents can book a live chat with an employee from a ticket
                  inside Teams.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employee burnout, exhaustion, and churn can be reduced
                </li>
                <li className="font-section-normal-text-testimonials">
                  Reduce the cost of providing employee assistance at a large
                  scale
                </li>
                <li className="font-section-normal-text-testimonials">
                  Increase in employee engagement
                </li>
                <li className="font-section-normal-text-testimonials">
                  Increased employee efficiency, which in turn improves
                  organizational ROI
                </li>
                <li className="font-section-normal-text-testimonials">
                  Offers personalization and involves a human-centric approach
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                MS Teams Bot for Service Desk Use cases
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk serves a broader scope to ramp up operational
                resiliency for an organization, enabling businesses to handle
                both help desk and service desk requests at scale. Here are the
                top use cases for every industry.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                MS Teams Chatbot for IT
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ conversational AI platform is a powerful MS Teams Bot
                for the service desk. It helps you augment IT services, which
                enhances employee productivity and solves more tickets in less
                time.
              </p>
              <h4 className="font-section-normal-text-medium color-black">
                Password reset automation{" "}
              </h4>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="MS Teams Bot for the service desk to enable password reset automation"
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Answer typical IT questions, such as "How do I update the
                password on my laptop" with{" "}
                <a href="https://workativ.com/use-cases/reset-password-automation">
                  password reset automation.
                </a>{" "}
                Check how it works.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  A user sends out a message in an MS Teams bot chat interface
                </li>
                <li className="font-section-normal-text-testimonials">
                  The bot asks ‘how can I help you?’
                </li>
                <li className="font-section-normal-text-testimonials">
                  A user says, ‘I forget my password’
                </li>
                <li className="font-section-normal-text-testimonials">
                  The bot asks for an email ID
                </li>
                <li className="font-section-normal-text-testimonials">
                  The bot verifies the email ID in the IAM application and
                  verifies it
                </li>
                <li className="font-section-normal-text-testimonials">
                  It sends a link to mail to reset the password
                </li>
                <li className="font-section-normal-text-testimonials">
                  A user updates her password, and it gets updated in the IAM
                  application
                </li>
                <li className="font-section-normal-text-testimonials">
                  Once the issue is resolved, a close ticket will be created for
                  the issue
                </li>
              </ul>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt=" password automation with MS Teams Bot for service desk"
              />
              <h4 className="font-section-normal-text-medium color-black">
                Dynamic notification automation
              </h4>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="bot notifications with MS Teams Bot for service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                IT functions can be automated, such as delivering outage
                warnings on Teams, giving asset change notifications, and more.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Proactively notify users on chat to take action at the
                workplace. Be it updating their passwords, filling surveys,
                updating OKRs, and personal info, or simply surprising them on
                their birthdays/anniversaries, etc., dynamic notifications, when
                automated, alert your employees and urge them to necessary
                action. Workativ provides{" "}
                <a href="https://workativ.com/conversational-ai-platform/features">
                  effective bot notifications for critical IT issues
                </a>{" "}
                and prevents outages.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Some other IT use cases include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Create tickets for service outages, IT failures, and service
                  outage requests.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Streamline IT onboarding activities such as requesting
                  specific computers, establishing email services, etc.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                MS Teams Chatbot for HR{" "}
              </h3>
              <h4 className="font-section-normal-text-medium color-black">
                Automate multiple administrative tasks
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Answer questions about leave balances, income taxes, annual
                vacations, HR regulations, etc.{" "}
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Say when your employees need to know about their leave balances,
                it is a lot of manual tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                How much vacation time does each employee get each year? More
                actionable form of requests includes applying for leaves,
                sending for approvals, etc. Many requests like that require an
                approval procedure and the use of specific systems your company
                employs.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Leave management automation within an MS Teams bot for service desk
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                You can design an MS Teams Bot for the service desk for{" "}
                <a href="https://workativ.com/use-cases/leave-management-automation">
                  leave management
                </a>{" "}
                automation. Get the bot and{" "}
                <a href="https://workativ.com/conversational-ai-platform/bamboohr-chatbot">
                  link it to HRSM systems like BambooHR
                </a>{" "}
                and use it as a platform for more straightforward procedures
                instead of manually doing leave management.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What a tweet by Matt Wade (@thatmattwade) says resonates with
                Workativ's assistant for HR systems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                “The natural language understanding can know what you mean and
                put in the request—kick off the system the way it would normally
                go if you’re in ADP or SAP or some other HR software. The bot
                can basically be doing all the hand-holding and connection
                between the services.”
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Some other HR use cases include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Simplify{" "}
                  <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                    recruiting and onboarding tasks
                  </a>{" "}
                  such as document proofing, and interview scheduling with
                  automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Allow workers to request time off, plan meetings, and book
                  travel, among other things.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Allow workers to request time off, organize meetings, submit
                  approval requests, etc
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                ChatBot for Business Intelligence (BI)
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Text or multimedia retrieval of needed data from the BI app
                  (reports, graphs, dashboard screenshots, etc.). Ex:
                  Operational expenses in the last business quarter
                </li>
                <li className="font-section-normal-text-testimonials">
                  Request reports or dashboard links through MS Teams chatbot.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Intranet Chatbot
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Files, papers, and vital information can be retrieved anywhere
                  on the company's internal network.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Get customized and relevant search results from all internal
                  intranet applications.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Did you know you can transform your Teams into an internal IT
                help desk with on-the-go self-service?
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ is a no-code interface solution that lets you create
                sophisticated, intelligent, and responsive conversational
                chatbots that work in tandem with Microsoft Teams. We offer
                customization based on your specific needs to improve employee
                engagement and productivity and ultimately increase ROI without
                needing a specialized development environment.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Use Workativ to sky-rocket your organizational productivity! To
                learn more,{" "}
                <a href="/conversational-ai-platform/demo">
                  schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h2 class="font-section-sub-header-small color-black">
                How does the MS Teams Bot lead to service desk transformation?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams bot can integrate with service desk platforms and
                efficiently use automation to streamline IT support workflows
                for MS Teams. Employees can quickly transition from traditional
                service desks to modernized service desks, enhancing employee
                user experience through faster resolution of IT issues.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                What benefits does the MS Teams Bot offer regarding service desk
                transformation?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams bot can give multiple benefits to service desk users.
                Employees can automate the resolution of IT issues through
                self-service capabilities, enable service desk agents to focus
                on more critical issues, and also foster cross-team
                collaborations to drive to proactive service desks.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                How does the MS Teams Bot drive cultural change within the
                service desk environment?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                The MS Teams bot can foster a cultural change through
                self-service enablement and empowerment for IT support users and
                agents. This change encourages teams to reduce common problems
                autonomously while also improving service desks overall.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Challenges with traditional service desk
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. How does MS Teams Bot enhance service desk
                    transformation?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Other advantages of using Microsoft Team Bots in the
                    service desk include:
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. MS Teams Bot for Service Desk Use cases
                  </AnchorLink>

                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Did you know you can transform your Teams into an
                    internal IT help desk with on-the-go self-service?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Enterprise operations maturity for operational efficiency"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                Every year, billions of dollars are spent on software linked to
                digital transformation. IT Service Desks are now in charge of
                integrating these new service desk software installations tied
                to massive digital transformation programs. Consequently, ticket
                traffic increases, putting pressure on ticketing costs and the
                IT service desk. Turn your familiar communication tool Microsoft
                Teams into a Service Desk with bot integration and app workflow
                automation.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Teams Service Desk helps the IT service desk become lean and
                flexible, allowing end-users to remain afloat and sail over the
                technological tsunami. By helping to automate routine tasks,
                these Microsoft Teams bots are streamlining processes and
                connecting employees and customers. It can also be used to
                execute the ITIL framework's best practices for integrating
                service delivery with strategic goals.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                How do you determine “ your business needs a conversational AI
                MS Teams Bot for the service desk”? Given your passive or
                reactive outlook in handling service desk issues, the question
                may seem irrelevant. But, workplace productivity, especially
                when you have a growing team and large client base, real-time
                and end-to-end service delivery is primary.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft, in its{" "}
                <a href="https://www.microsoft.com/en-us/worklab/four-ways-leaders-can-empower-people-for-how-work-gets-done">
                  survey,
                </a>{" "}
                highlighted 77% of respondents' view on low-code or no-code
                solutions to be helping them achieve their goals at work. On the
                other hand, 89% of respondents cited that they are more
                productive and fulfilled with automation and AI-powered tools.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this context, it wouldn’t be wrong to say that service desk
                transformation through AI-powered MS Teams bot integration,
                including app workflow automation, would serve the purpose of
                what employees need to resolve issues and thrive at the
                workplace.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                This blog will identify service desk issues and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  the need for service desk transformation.
                </a>{" "}
                We'll further examine how the{" "}
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  Microsoft Teams bot for the service desk
                </a>{" "}
                delivers process efficiency and business resilience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Challenges with traditional service desk
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Streamlining work processes is the key objective of a service
                desk. But, when your service desk is built on a traditional
                model, it gradually adds to poor employee experience and hits
                the bottom line. Below are some striking challenges of a service
                desk.
              </p>
              <BlogCta
                ContentCta="Auto-resolve 60% of Employee Queries with MS Teams Bot."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <h3 className="font-section-sub-header-small-home">
                A steep learning curve needed
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Suppose you have ever noticed a recruitment drive for an ITSM
                manager or expert. In that case, it generally focuses on key
                expertise areas to handle major modules of a service desk
                platform in mitigating IT incidents and ensuring business
                continuity.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A steep learning curve about the platform architecture can be
                ideal for handling service desk tickets and driving employee
                efficiency. It means a service desk is built to help agents to
                resolve issues.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                What if end users do not possess a sound knowledge of the
                platform? Instead of using the service desk portal, they tend to
                use multiple methods such as emails, phone calls, or one-to-one
                interaction to communicate the issue.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A bottom line crisis as your investment remains unutilized.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Lengthy Mean Time to Resolution (MTTR)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                As per Service Desk Show,{" "}
                <a href="https://www.servicedeskshow.com/blog-spot/does-my-company-need-a-conversational-ai-chatbot/">
                  monthly tickets have grown by 35% over the two years.
                </a>{" "}
                Coupled with the complexity of issues and a backlog of tickets,
                there is a rush to handle as many tickets as possible, which is
                a possible reason for committing mistakes and handling the same
                tickets repeatedly. Besides, a lack of context to the actual
                issue of a ticket usually prolongs the MTTR for a service desk
                agent.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Handling multiple requests altogether
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                A traditional service desk is not centralized and is supported
                by phone calls and emails. As a result,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Phone calls take more time per user to triage and close
                  tickets
                </li>
                <li className="font-section-normal-text-testimonials">
                  Emails make it challenging to track progress back and forth,
                  causing delays in ticket resolution
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                This perhaps increases the total count of tickets to your
                service desk involving the same or more complex issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Handling a wave of tickets from your growing team is
                nerve-wracking, mainly because you are yet to bring automation
                to your service desk. Adopting chatbots is a cost-effective
                option as these sophisticated algorithms can handle numerous
                customers simultaneously, lowering wait time and freeing support
                employees to focus on problem-solving.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Repetitive service desk issues
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                A help desk is susceptible to backlog issues when failing to
                resolve current issues at scale.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  IT help desk gets into the trap of resolving the same issues
                  as a traditional system lacks data analytics which can help
                  collect, record, and analyze support data.
                </li>
                <li className="font-section-normal-text-testimonials">
                  IT teams are unable to address more critical issues because of
                  high volumes of FAQ-based or common IT service-related
                  queries.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unable to keep track of the most common issues, service desk
                agents will waste time finding and addressing issues they have
                already worked on.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Lack of effective self-service interface
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Many of the issues dealt with by the IT service desk are
                regular, repetitive, and time-consuming.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Password resets account for 50 percent of IT service desk
                  calls
                </li>
                <li className="font-section-normal-text-testimonials">
                  Also, password reset or group email access may take more than
                  an hour to resolve due to the dependency on human involvement
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Where 80% of productivity losses happen due to requests for a
                repetitive issue, the ability to auto-resolve can reduce this
                probability. But, a traditional service desk does not incite
                quick adoption and increase service request volumes.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Long resolution times and unresolved issues
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The more tools and platforms in a business, the greater the
                requirement for faster troubleshooting. When the service desk
                gets too many requests to handle and lacks the appropriate
                communication tools, this will create massive damage. Many IT
                help desks use email to track client queries, and when employees
                manually sift through these inquiries, productivity suffers. The
                IT service desk also misses the chance to gain data that can
                identify recurrent issues. It ultimately speeds up future
                resolution times if it does not have a structured ticket intake
                approach and request tracking tools.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft Teams' strength lies in the fact that it enables users
                to interact while keeping in context. Bots created on the Teams
                platform operate as intelligent connectors between the Teams
                client and other services and processes, integrating them into
                your active collaboration platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They will help you save time by automating mundane chores with
                artificial intelligence. One of the most valuable features of
                Microsoft Teams is having a bot appear as any other team member
                to assist. T-Bot, for example, is a bot that answers commonly
                requested queries. Polly Bot may assist with polling among
                coworkers. Hipmunk can assist you in making travel arrangements.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of sending employees to a separate HR system to read
                vacation policies. a bot that tells them how many hours of
                vacation they have left for the year and walks them through the
                process without even leaving Microsoft Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  MS Teams bots have various applications, including{" "}
                </a>
                -One-to-one, group, and channel in a team. Users communicate
                with bots via text, interactive cards, and task modules, and
                interactions may vary from a basic search to a complicated
                AI-powered dialogue. Conversational bots make sense in Teams
                since it's a chat-based collaboration tool—the only difference
                is that you're conversing with a machine instead of another
                human. Great, isn't it?
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does MS Teams Bot enhance service desk transformation?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft Teams Bot for service desk can easily fetch service
                desk data inside your familiar collaboration channel Microsoft
                Teams through an API call. Chatbot builder such as{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ Assistant
                </a>{" "}
                helps you create app{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/enterprise-service-desk-guide">
                  workflow automation for service desk platforms
                </a>{" "}
                and enable handling IT or HR issues at scale inside the chat
                interface of MS Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s how your employee can achieve operational efficiency and
                drive business growth for you.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Ease of adoption for organization-wide users
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unlike traditional service desk platform, which needs a deep
                learning curve for adoption, MS Team Bot for service desk is
                easy to raise issues. Its chat interface acts as a service desk
                portal to raise tickets and get real-time help from an agent
                without continuously logging in and out of the system. It is a
                universal interface rather than just for agents and facilitates
                auto-resolutions rapidly and accurately.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Continuous updating of knowledge base content
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Traditional service desk incorporates knowledge materials with
                outdated information and offers no real value to the present-day
                service desk issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  An AI-modern service desk
                </a>{" "}
                keeps updating its knowledge repository and database by learning
                patterns of unique cases. As a result, employees need not wait
                for human help as they can easily fetch information from its
                portal. It is even easier to get knowledge articles and
                auto-resolve issues when a service desk is integrated with the
                MS Teams bot.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Real-time employee support
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is proven that the service desk is familiar with more than
                80% of repetitive tasks. ML and NLU-based service desk portal
                fetches real-time and appropriate knowledge base articles
                relevant to specific service desk issues.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                If knowledge articles are inappropriate, MS Teams Bot for the
                service desk automatically escalates the matter to the live
                agent. So, employees always get real-time employee support.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Self-service functionality
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is no denying that a modern service desk also comes with a
                dedicated self-service portal. But, it is pretty difficult for
                off-site or hybrid employees to connect with the portal, given
                the lack of easy access to system credentials.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                While MS Teams Bot for service desk is a familiar platform for
                employees 一 no matter whether remote or on-site, they can
                easily communicate their issues in the chat interface and get
                solutions rapidly within their portals.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Automated app workflows with service desk integration
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams bot for the service desk can flexibly adapt to any
                service desk infrastructure without major tweaks on the
                server-side end. A conversational AI platform, Workativ virtual
                assistant is easy to build your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/the-service-desk-trends-and-priorities-for-2023">
                  service desk chatbots for existing or new tools
                </a>{" "}
                such as ServiceNow, Freshservice, Zendesk, HaloITSM, and many
                more.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, leverage the integration and{" "}
                <a href="https://workativ.com/conversational-ai-platform/servicenow-chatbot">
                  automate app workflows
                </a>{" "}
                for just any service desk issues.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Accelerated resolution time
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Team bot integration fits seamlessly into IT service desk
                settings for ITSM automation, especially as Teams becomes more
                popular as a preferred route for employee communication. It's a
                go-to IT and HR channel for workers since it doesn't need them
                to log into service desk environments or portals. Their queries
                are auto-resolved in seconds utilizing AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ conversational AI is built to{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  reduce MTTR by 5X from hours to seconds.{" "}
                </a>
              </p>
              <h3 className="font-section-sub-header-small-home">
                Elimination of lower-level support tasks through automation
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams Bots for the service desk can assist with lower-lever
                support tasks to help lessen the strain on IT, admin, and
                support teams. This is a significant advantage of bots that has
                become more obvious as many workers have transitioned to remote
                work. The increased use of Teams has resulted in an
                overburdening of work for those working in customer service and
                IT. A "help bot" that can assist with requests addressed to IT
                is one of the most common bots people like to design.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Many inquiries that come in to help desks aren't actionable
                troubleshooting concerns. Therefore,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk">
                  help desk services are readily automated,
                </a>{" "}
                particularly tier-one-level replies. You can have standard
                answers available 24/7 using cutting-edge
                <a href="https://workativ.com/conversational-ai-platform/faq-chatbot-builder">
                  FAQ-based knowledge resources
                </a>{" "}
                for issues such as,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Printer issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Laptop issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Internet issues
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                When a bot can thoroughly diagnose and resolve an issue for a
                user, it saves time for IT professionals.
              </p>
              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
              <h3 className="font-section-sub-header-small-home">
                Serving as the perfect support center
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee productivity has increased dramatically due to the
                user-friendly interface combining the service desk and Teams.
                The speed with which difficulties are resolved doubles when
                Conversational AI, NLU, Cognitive Search, and NLP are used. MS
                Teams now has an AI-driven workflow automation engine that
                automates user processes and tasks.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Increase in employee satisfaction and engagement
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The ability of Microsoft Teams to modify self-service has been
                crucial in automating internal services and enhancing service
                desk productivity. Due to{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/benefits-of-ai-powered-service-desk">
                  AI-powered auto-resolution,
                </a>{" "}
                customers have seen an increase in user engagement and a
                significant drop in tickets because of Teams. What were the
                results? Employee satisfaction has risen, agent productivity has
                doubled, and average resolution rates have risen, making Service
                Desk efficiency a key performance indicator.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Other advantages of using Microsoft Team Bots in the service
                desk include:
              </h2>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Allow employees to respond to comments on complaints, approval
                  requests, and modifications using actionable alerts in
                  Microsoft Teams.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employees can access their pending assignments, the status of
                  their tickets, and business records.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Agents can book a live chat with an employee from a ticket
                  inside Teams.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employee burnout, exhaustion, and churn can be reduced
                </li>
                <li className="font-section-normal-text-testimonials">
                  Reduce the cost of providing employee assistance at a large
                  scale
                </li>
                <li className="font-section-normal-text-testimonials">
                  Increase in employee engagement
                </li>
                <li className="font-section-normal-text-testimonials">
                  Increased employee efficiency, which in turn improves
                  organizational ROI
                </li>
                <li className="font-section-normal-text-testimonials">
                  Offers personalization and involves a human-centric approach
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                MS Teams Bot for Service Desk Use cases
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk serves a broader scope to ramp up operational
                resiliency for an organization, enabling businesses to handle
                both help desk and service desk requests at scale. Here are the
                top use cases for every industry.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                MS Teams Chatbot for IT
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ conversational AI platform is a powerful MS Teams Bot
                for the service desk. It helps you augment IT services, which
                enhances employee productivity and solves more tickets in less
                time.
              </p>
              <h4 className="font-section-normal-text-medium color-black">
                Password reset automation{" "}
              </h4>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="MS Teams Bot for the service desk to enable password reset automation"
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Answer typical IT questions, such as "How do I update the
                password on my laptop" with{" "}
                <a href="https://workativ.com/use-cases/reset-password-automation">
                  password reset automation.
                </a>{" "}
                Check how it works.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  A user sends out a message in an MS Teams bot chat interface
                </li>
                <li className="font-section-normal-text-testimonials">
                  The bot asks ‘how can I help you?’
                </li>
                <li className="font-section-normal-text-testimonials">
                  A user says, ‘I forget my password’
                </li>
                <li className="font-section-normal-text-testimonials">
                  The bot asks for an email ID
                </li>
                <li className="font-section-normal-text-testimonials">
                  The bot verifies the email ID in the IAM application and
                  verifies it
                </li>
                <li className="font-section-normal-text-testimonials">
                  It sends a link to mail to reset the password
                </li>
                <li className="font-section-normal-text-testimonials">
                  A user updates her password, and it gets updated in the IAM
                  application
                </li>
                <li className="font-section-normal-text-testimonials">
                  Once the issue is resolved, a close ticket will be created for
                  the issue
                </li>
              </ul>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt=" password automation with MS Teams Bot for service desk"
              />
              <h4 className="font-section-normal-text-medium color-black">
                Dynamic notification automation
              </h4>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="bot notifications with MS Teams Bot for service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                IT functions can be automated, such as delivering outage
                warnings on Teams, giving asset change notifications, and more.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Proactively notify users on chat to take action at the
                workplace. Be it updating their passwords, filling surveys,
                updating OKRs, and personal info, or simply surprising them on
                their birthdays/anniversaries, etc., dynamic notifications, when
                automated, alert your employees and urge them to necessary
                action. Workativ provides{" "}
                <a href="https://workativ.com/conversational-ai-platform/features">
                  effective bot notifications for critical IT issues
                </a>{" "}
                and prevents outages.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Some other IT use cases include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Create tickets for service outages, IT failures, and service
                  outage requests.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Streamline IT onboarding activities such as requesting
                  specific computers, establishing email services, etc.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                MS Teams Chatbot for HR{" "}
              </h3>
              <h4 className="font-section-normal-text-medium color-black">
                Automate multiple administrative tasks
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Answer questions about leave balances, income taxes, annual
                vacations, HR regulations, etc.{" "}
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Say when your employees need to know about their leave balances,
                it is a lot of manual tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                How much vacation time does each employee get each year? More
                actionable form of requests includes applying for leaves,
                sending for approvals, etc. Many requests like that require an
                approval procedure and the use of specific systems your company
                employs.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Leave management automation within an MS Teams bot for service desk
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                You can design an MS Teams Bot for the service desk for{" "}
                <a href="https://workativ.com/use-cases/leave-management-automation">
                  leave management
                </a>{" "}
                automation. Get the bot and{" "}
                <a href="https://workativ.com/conversational-ai-platform/bamboohr-chatbot">
                  link it to HRSM systems like BambooHR
                </a>{" "}
                and use it as a platform for more straightforward procedures
                instead of manually doing leave management.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What a tweet by Matt Wade (@thatmattwade) says resonates with
                Workativ's assistant for HR systems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                “The natural language understanding can know what you mean and
                put in the request—kick off the system the way it would normally
                go if you’re in ADP or SAP or some other HR software. The bot
                can basically be doing all the hand-holding and connection
                between the services.”
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Some other HR use cases include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Simplify{" "}
                  <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                    recruiting and onboarding tasks
                  </a>{" "}
                  such as document proofing, and interview scheduling with
                  automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Allow workers to request time off, plan meetings, and book
                  travel, among other things.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Allow workers to request time off, organize meetings, submit
                  approval requests, etc
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                ChatBot for Business Intelligence (BI)
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Text or multimedia retrieval of needed data from the BI app
                  (reports, graphs, dashboard screenshots, etc.). Ex:
                  Operational expenses in the last business quarter
                </li>
                <li className="font-section-normal-text-testimonials">
                  Request reports or dashboard links through MS Teams chatbot.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Intranet Chatbot
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Files, papers, and vital information can be retrieved anywhere
                  on the company's internal network.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Get customized and relevant search results from all internal
                  intranet applications.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Did you know you can transform your Teams into an internal IT
                help desk with on-the-go self-service?
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ is a no-code interface solution that lets you create
                sophisticated, intelligent, and responsive conversational
                chatbots that work in tandem with Microsoft Teams. We offer
                customization based on your specific needs to improve employee
                engagement and productivity and ultimately increase ROI without
                needing a specialized development environment.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Use Workativ to sky-rocket your organizational productivity! To
                learn more,{" "}
                <a href="/conversational-ai-platform/demo">
                  schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h2 class="font-section-sub-header-small color-black">
                How does the MS Teams Bot lead to service desk transformation?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams bot can integrate with service desk platforms and
                efficiently use automation to streamline IT support workflows
                for MS Teams. Employees can quickly transition from traditional
                service desks to modernized service desks, enhancing employee
                user experience through faster resolution of IT issues.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                What benefits does the MS Teams Bot offer regarding service desk
                transformation?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams bot can give multiple benefits to service desk users.
                Employees can automate the resolution of IT issues through
                self-service capabilities, enable service desk agents to focus
                on more critical issues, and also foster cross-team
                collaborations to drive to proactive service desks.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                How does the MS Teams Bot drive cultural change within the
                service desk environment?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                The MS Teams bot can foster a cultural change through
                self-service enablement and empowerment for IT support users and
                agents. This change encourages teams to reduce common problems
                autonomously while also improving service desks overall.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              How can Enterprises Leverage Generative AI?
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/chatgpt-vs-conversational-ai"
              className="font-section-normal-text-testimonials"
            >
              Why Is ChatGPT Not The Answer To Your Enterprise Conversational AI
              Needs?
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
